import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { barryApi, MessageResponse } from 'redux/api/barryApi'
import { pageActions } from 'redux/slices/page'
import { RootState } from 'redux/store'
import { getSessionToken } from 'utils/localStorage'
import { v4 as uuidv4 } from 'uuid'
import { getTime } from 'utils/time'

enum ErrorTypes {
  ConversationConcluded = 'ConversationConcluded',
}

export interface MessageState {
  messages: Message[]
  conversationId: string
  confidenceRatings: number[]
  ticketSubmitted: boolean
  timestamp?: number
  needsHuman: boolean
  isOffence?: boolean
  isBarryTyping: boolean
  lastMessageFromBarry?: Message
  chatEndsAt?: Date
}

export interface Message {
  content: string
  fromAgent: boolean
  fromBarry: boolean
  sender: string
  timestamp: number
  needsHuman?: boolean
  hasErrored?: boolean
  errorMessage?: string
  isOffence?: boolean
  time?: string
  forceEndConversation?: boolean
}

const typingDelay = 500
const minTypingTime = 500

export const endChat = createAsyncThunk('message/endChat', async (_, { dispatch, signal }) => {
  signal.dispatchEvent(new Event('abort'))
  dispatch(messageActions.initialState())
})

export const sendMessage = createAsyncThunk(
  'message/sendMessage',
  async (messageToSend: string, { dispatch, getState }) => {
    const { message } = getState() as RootState
    const cd_session_auth = getSessionToken()
    dispatch(messageActions.extendChat())
    const response = dispatch(
      barryApi.endpoints.sendMessage.initiate(
        {
          conversationId: message.conversationId,
          message: messageToSend,
          ...(cd_session_auth && { sessionAuthHash: cd_session_auth }),
        },
        //This cache key allows us to read if the request is flying anywhere in the code, provided we use the same key
        { fixedCacheKey: 'sendMessage' },
      ),
    )

    const startTypingTimerId = setTimeout(() => {
      dispatch(messageActions.setTyping(true))
    }, typingDelay)

    if (process.env.REACT_APP_DEBUG === 'true') {
      response
        .unwrap()
        .then(response => {
          // eslint-disable-next-line no-console
          console.log(response)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }

    setTimeout(() => {
      response
        .unwrap()
        .then(response => {
          if (response.output[0]) {
            dispatch(messageActions.addMessageFromBarry(response))
          }
        })
        .catch(() => {
          clearTimeout(startTypingTimerId)
        })
        .finally(() => {
          dispatch(messageActions.setTyping(false))
        })
    }, typingDelay + minTypingTime)

    response.unwrap().catch(err => {
      if (err.status === 400 && err.data.reason === ErrorTypes.ConversationConcluded) {
        dispatch(messageActions.initialState())
        dispatch(pageActions.clear())
      }
    })
  },
)

export const retrySendMessage = createAsyncThunk(
  'message/retry',
  async (
    { timestamp, messageToSend }: { timestamp: number; messageToSend: string },
    { dispatch },
  ) => {
    dispatch(messageActions.removeMessage(timestamp))
    dispatch(sendMessage(messageToSend))
  },
)

export const messageInitialState = (): MessageState => {
  return {
    messages: [],
    conversationId: uuidv4(),
    confidenceRatings: [],
    ticketSubmitted: false,
    timestamp: undefined,
    needsHuman: false,
    isOffence: false,
    isBarryTyping: false,
    lastMessageFromBarry: undefined,
    chatEndsAt: undefined,
  }
}

const slice = createSlice({
  name: 'message',
  initialState: messageInitialState(),
  reducers: {
    addMessage(state: MessageState, { payload }: PayloadAction<Message>) {
      state.messages = [...state.messages, payload]
    },
    addMessageFromBarry(state: MessageState, { payload }: PayloadAction<MessageResponse>) {
      if (state.conversationId !== payload.conversationId) {
        return
      }
      //Assign the conversationId in the first iteration
      if (!state.conversationId) {
        state.conversationId = payload.conversationId
      }

      state.timestamp = Math.floor(Date.now() / 1000)
      state.confidenceRatings = [
        ...state.confidenceRatings,
        ...payload.intents.map(intent => intent.confidence),
      ]

      //checks for {"agent_id":"human"} or similar inside the message returned by Barry
      const needsHumanRegexOldFormat = /{"agent_id":"human"}/g
      const needsHumanRegexNewFormat = /{{action-human}}/g
      const needsHuman =
        !!payload.output[0].match(needsHumanRegexOldFormat) ||
        !!payload.output[0].match(needsHumanRegexNewFormat)
      state.needsHuman = needsHuman
      const endConversationRegex = /{{action-end-conversation}}/g
      const endConversation = !!payload.output[0].match(endConversationRegex)

      const offenceRegex = /{{force_close}}/g
      const isOffence = !!payload.output[0].match(offenceRegex)
      state.isOffence = isOffence

      const processMessage = (payload: MessageResponse, ...regexes: RegExp[]) => {
        const barryResponseMessage = payload.output[0]

        for (const regex of regexes) {
          if (barryResponseMessage.match(regex)) {
            return barryResponseMessage.replace(regex, '')
          }
        }
        return barryResponseMessage
      }

      const messageContent = processMessage(
        payload,
        needsHumanRegexOldFormat,
        needsHumanRegexNewFormat,
        offenceRegex,
        endConversationRegex,
      )

      const message: Message = {
        content: messageContent,
        fromAgent: false,
        fromBarry: true,
        sender: 'barry',
        timestamp: Math.floor(Date.now() / 1000),
        needsHuman,
        isOffence,
        time: getTime(),
        forceEndConversation: endConversation,
      }

      state.messages = [...state.messages, message]
    },
    initialState: () => messageInitialState(),
    setTyping(state: MessageState, { payload }: PayloadAction<boolean>) {
      state.isBarryTyping = payload
    },
    setMessageError(
      state: MessageState,
      { payload }: PayloadAction<{ timestamp: number; errorMessage: string }>,
    ) {
      state.messages = state.messages.map(message =>
        message.timestamp === payload.timestamp
          ? { ...message, hasErrored: true, errorMessage: payload.errorMessage }
          : message,
      )
    },
    removeMessage(state: MessageState, { payload }: PayloadAction<number>) {
      state.messages = state.messages.filter(message => message.timestamp !== payload)
    },
    extendChat(state: MessageState) {
      const expirationTimeInMinutes = 60
      state.chatEndsAt = new Date(new Date().getTime() + expirationTimeInMinutes * 60 * 1000)
    },
  },
})

export const { reducer: messageReducer, actions: messageActions } = slice

export default slice
