export default {
  primary: {
    borderRadius: '1.2rem',
    backgroundColor: 'smokyBlack',
    border: '1px solid',
    outline: 'none',
    borderColor: 'blueYonder50',
    p: '1.6rem',
    fontSize: '1.5rem',
    color: 'aliceBlue30',
    fontWeight: 400,
    letterSpacing: '0.6px',
    '&:focus': {
      borderColor: 'mayaBlue70',
      color: 'aliceBlue',
      opacity: 1,
    },
    '&:hover:not(:focus)': {
      borderColor: 'blueYonder80',
    },
    '::placeholder': {
      opacity: 0.6,
      color: 'caption',
      fontWeight: 500,
      letterSpacing: '0.6px',
    },
  },
}
