import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cdApi } from 'redux/api/cdApi'

export enum Page {
  SPLASH_PAGE = 0,
  DESCRIBE_ISSUE_PAGE = 1,
  CHAT_PAGE = 3,
  SUBMIT_TICKET = 4,
  TICKET_SENT = 5,
  SOMETHING_WENT_WRONG = 8,
}

type PageView = {
  page: Page
}

const initialState: PageView = { page: Page.SPLASH_PAGE }

const pageSlice = createSlice({
  name: 'pages',
  initialState: initialState,
  reducers: {
    switchPage: (state, action: PayloadAction<Page>) => {
      state.page = action.payload
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(cdApi.endpoints.sendSupportTicket.matchFulfilled, state => {
      state.page = Page.TICKET_SENT
    })
  },
})

export const { actions: pageActions, reducer: pageReducer } = pageSlice
export default pageSlice
