export default {
  primary: { cursor: 'pointer' },
  neon: {
    cursor: 'pointer',
    height: '48px',
    borderRadius: '40px',
    padding: '12px 24px 12px 24px',
    backgroundColor: 'screaminGreen',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      border: 'none',
      backgroundColor: 'white10',
      '& > span': {
        color: 'white25',
      },
    },
  },
  transparent: {
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
  },
  gradientButton: {
    alignItems: 'center',
    py: '12px',
    px: '20px',
    gap: '5px',
    fontSize: '17px',
    borderRadius: '50px',
    background: 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
    boxShadow:
      '0px 0px 20px 0px rgba(97, 63, 219, 0.6), 0px 1px 0.2px 0px rgba(255, 255, 255, 0.4) inset',
    '&:hover': {
      ':not([disabled])': {
        cursor: 'pointer',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    '&:active': {
      ':not([disabled])': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    ':disabled': {
      cursor: 'auto',
      background: 'darkJungelGreen',
      color: 'title20',
      borderRadius: '100px',
      boxShadow: 'none',
      '& > div span': {
        color: 'white25',
      },
      '& svg path': {
        fill: 'white25',
      },
    },
  },
  secondaryRounded: {
    cursor: 'pointer',
    outline: 'none',
    py: '9px',
    px: '20px',
    borderRadius: '10rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '17px',
    color: 'white',
    fontFamily: 'PlusJakartaSans, Arial, sans-serif',
    border: '1px solid',
    borderColor: 'mayaBlue60',
    backgroundColor: 'lightSkyBlue10',
    '&:hover': {
      backgroundColor: 'lightSkyBlue15',
    },
    '&:active': {
      backgroundColor: 'lightSkyBlue5',
    },
  },
}
