import { ReactElement } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { endChat } from 'redux/slices/message'
import { pageActions } from 'redux/slices/page'
import { Button, Flex, Text } from 'theme-ui'
import { ReactComponent as BarryIconBackground } from 'assets/barry-background.svg'
import { ReactComponent as TicketSentIcon } from 'assets/ticket-sent.svg'

export default function TicketSent({
  handleBarryIconToggle,
}: {
  handleBarryIconToggle: () => void
}): ReactElement {
  const dispatch = useAppDispatch()

  const handleSubmit = () => {
    dispatch(endChat())
    dispatch(pageActions.clear())
    handleBarryIconToggle()
  }

  return (
    <>
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <BarryIconBackground />
      </Flex>
      <Flex
        data-testid="ticket-sent-view"
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TicketSentIcon
          sx={{
            zIndex: 'zIndex0',
          }}
        />
        <Text
          sx={{
            color: 'caption',
            fontSize: '28px',
            marginBottom: '8px',
            fontWeight: 'bold',
            pt: '11px',
          }}
        >
          Ticket Sent!
        </Text>
        <Flex
          sx={{
            color: 'body',
            fontSize: '15px',
            marginBottom: '24px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text sx={{ textAlign: 'center' }}>Our support team will get back to you shortly.</Text>
        </Flex>
        <Button
          variant="gradientButton"
          data-testid="splash-screen-button"
          onClick={handleSubmit}
          sx={{ p: '12px 20px' }}
        >
          <Text sx={{ fontSize: '18px', fontWeight: 'bold', width: '88px' }}>Close</Text>
        </Button>
      </Flex>
    </>
  )
}
