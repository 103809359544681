import { ReactElement, useEffect, useState } from 'react'
import { Button, Flex, Text } from 'theme-ui'
import { ReactComponent as BarryIcon } from 'assets/barry-icon.svg'
import { ReactComponent as BarryIconBackground } from 'assets/barry-background.svg'
import { Page, pageActions } from 'redux/slices/page'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { offenceActions } from 'redux/slices/offence'

export default function SplashScreen(): ReactElement {
  const dispatch = useAppDispatch()
  const { lastOffenceTimestamp, isBanned } = useAppSelector(x => x.offence)
  const [banCountdown, setBanCountdown] = useState({ hours: 24, minutes: 0, seconds: 0 })
  const dayInMilliseconds = 1000 * 60 * 60 * 24

  function switchView(page: Page) {
    dispatch(pageActions.switchPage(page))
  }

  function formatTimer(hours: number, minutes: number, seconds: number) {
    return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`
    }`
  }

  function isBanExpired() {
    if (lastOffenceTimestamp === undefined) {
      return true
    }
    const currentTime = Date.now()
    return (currentTime - lastOffenceTimestamp) / dayInMilliseconds > 1
  }

  function banTimer() {
    if (lastOffenceTimestamp === undefined) {
      return { hours: 0, minutes: 0, seconds: 0 }
    }
    const currentTime = Date.now()
    const timeLeft = lastOffenceTimestamp + dayInMilliseconds - currentTime
    return {
      hours: Math.floor(timeLeft / (1000 * 3600)),
      minutes: Math.floor((timeLeft % (1000 * 3600)) / (1000 * 60)),
      seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
    }
  }

  useEffect(() => {
    if (banCountdown.hours < 1 && banCountdown.minutes < 1 && banCountdown.seconds < 1) {
      dispatch(offenceActions.setBan(false))
      dispatch(offenceActions.updateTimestamp(undefined))
      return
    }
    if (isBanExpired()) {
      dispatch(offenceActions.setBan(false))
    }
    if (isBanned === true) {
      const interval = setInterval(() => {
        setBanCountdown(banTimer())
      }, 1000)
      return () => clearInterval(interval)
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banCountdown])

  return (
    <>
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
          overflow: 'hidden',
        }}
      >
        <BarryIconBackground />
      </Flex>
      <Flex
        data-testid="barry-splash-screen"
        sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 'zIndex1',
        }}
      >
        <Flex>
          <BarryIcon />
        </Flex>
        <Text
          sx={{
            pt: '16px',
            color: 'white',
            fontSize: '28px',
            fontWeight: 'bold',
            pb: '8px',
          }}
        >
          Ask Barry
        </Text>
        {!isBanned ? (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Flex
              sx={{
                color: 'body',
                marginBottom: '32px',
                flexDirection: 'column',
                alignItems: 'center',
                width: '320px',
                textAlign: 'center',
                fontSize: '15px',
              }}
            >
              <Text>I can help answer and troubleshoot any question or issue.</Text>
            </Flex>
            <Button
              variant="gradientButton"
              data-testid="splash-screen-button"
              onClick={() => switchView(Page.DESCRIBE_ISSUE_PAGE)}
            >
              <Text sx={{ fontSize: '17px', color: 'white', fontWeight: 400 }}>
                Ask me anything
              </Text>
            </Button>
          </Flex>
        ) : (
          <Flex
            data-testid="banned-splash-view"
            sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
          >
            <Flex
              sx={{
                color: 'body',
                marginBottom: '32px',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                fontSize: '15px',
              }}
            >
              <Text>Sorry, you&apos;re not able to talk to Barry</Text>
              <Text>right now, Please try again in 24 hours.</Text>
            </Flex>

            <Flex
              sx={{
                height: '48px',
                backgroundColor: 'darkJungelGreen',
                borderRadius: '40px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                sx={{
                  fontSize: '17px',
                  color: 'title20',
                  fontWeight: 'bold',
                  width: '100%',
                  px: '20px',
                  py: '12px',
                }}
              >
                {formatTimer(banCountdown.hours, banCountdown.minutes, banCountdown.seconds)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  )
}
