import { ReactElement } from 'react'

import {
  Button as ThemeUIButton,
  ButtonProps as ThemeUIButtonProps,
  Spinner,
  ThemeUIStyleObject,
} from 'theme-ui'

interface ButtonProps extends ThemeUIButtonProps {
  isLoading?: boolean
  styles?: ThemeUIStyleObject
}

export default function Button(props: ButtonProps): ReactElement {
  const { isLoading, styles, children, disabled, ...rest } = props

  return (
    <ThemeUIButton {...rest} sx={styles} disabled={isLoading || disabled}>
      {isLoading ? <Spinner size={24} duration={900} /> : children}
    </ThemeUIButton>
  )
}
