import { combineReducers } from '@reduxjs/toolkit'
import { PersistConfig } from 'redux-persist'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/es/storage'
import { barryApi } from 'redux/api/barryApi'
import { cdApi } from 'redux/api/cdApi'
import { messageInitialState, messageReducer, MessageState } from 'redux/slices/message'
import { offenceReducer } from 'redux/slices/offence'
import { pageReducer } from 'redux/slices/page'
import { userExperienceReducer } from 'redux/slices/userExperience'

const newReduxVersion = 2

const messagePersistConfig: PersistConfig<MessageState> = {
  key: 'message',
  storage: storage,
  blacklist: ['isBarryTyping'],
  version: newReduxVersion,
  migrate: (oldState, currentVersion) => {
    //oldState consists of the _persist property and the rest of the MessageState
    if (oldState && oldState?._persist) {
      //we extract the MessageState as ...rest since you PersistState is not generic
      const { _persist, ...rest } = oldState

      //then we assign the rest which is typed as an empty object as the MessageState
      const messageState = rest as MessageState

      //we only want to swap the state version if there is no conversationId
      //this prevents a refresh in the middle of a conversation from resetting the state and ending up in the chat screen with no messages
      if (currentVersion === newReduxVersion && !messageState.conversationId) {
        const initialState: MessageState = messageInitialState()
        return Promise.resolve({
          ...initialState,
          _persist: {
            version: 2,
            rehydrated: true,
          },
        })
      }
    }

    //if there is no old state or no conversationId, we just return the old state, which is the barry 1.0 state
    return Promise.resolve(oldState)
  },
}

const rootReducer = combineReducers({
  [barryApi.reducerPath]: barryApi.reducer,
  [cdApi.reducerPath]: cdApi.reducer,
  message: persistReducer(messagePersistConfig, messageReducer),
  page: pageReducer,
  offence: offenceReducer,
  userExperience: userExperienceReducer,
})

export default rootReducer
