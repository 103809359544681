import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserExperienceState {
  chatBubbleBeenClosedOn: number | undefined
}

const initialState: UserExperienceState = {
  chatBubbleBeenClosedOn: undefined,
}

const userExperienceSlice = createSlice({
  name: 'userExperience',
  initialState: initialState,
  reducers: {
    setChatBubbleBeenClosedOn(state, action: PayloadAction<number>): void {
      state.chatBubbleBeenClosedOn = action.payload
    },
    clear: () => initialState,
  },
})

export const { actions: userExperienceActions, reducer: userExperienceReducer } =
  userExperienceSlice
export default userExperienceSlice
