import { ReactComponent as AttachedIcon } from 'assets/attach-icon.svg'
import Button from 'components/UI/Button'
import { ReactElement, useState } from 'react'
import { cdApi } from 'redux/api/cdApi'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Page, pageActions } from 'redux/slices/page'
import { Box, Flex, Text } from 'theme-ui'
import { ReactComponent as ChatBackground } from 'assets/chat-background.svg'
import SupportTicketInput from './chat-ui/SupportTicketInput'
import { getEmail } from 'utils/localStorage'

const containerPaddingX = '15px'

export default function SubmitTicketView(): ReactElement {
  const dispatch = useAppDispatch()
  const { messages } = useAppSelector(x => x.message)
  const [email, setEmail] = useState(() => getEmail())
  const [comments, setComments] = useState('')
  const [
    sendSupportTicket,
    {
      isError: isSendSupportTicketError,
      isUninitialized: isSendSupportTicketUninitialized,
      isLoading: isSendSupportTicketLoading,
    },
  ] = cdApi.useSendSupportTicketMutation()
  const [
    getPreAuthToken,
    { isError: isPreAuthError, isLoading: isPreAuthLoading, isSuccess: isPreAuthSuccess },
  ] = cdApi.usePreAuthTokenMutation()

  const shouldDisplayErrorMessage = isSendSupportTicketError || isPreAuthError
  const isRequestLoading =
    isSendSupportTicketLoading ||
    isPreAuthLoading ||
    ((isPreAuthSuccess || isPreAuthError) && isSendSupportTicketUninitialized)

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        if (!email) {
          return
        }

        getPreAuthToken({ userEmail: email })
          .unwrap()
          .then(payload => {
            if (!!payload && !payload.error) {
              sendSupportTicket({
                email: email,
                subject: `Barry Support Ticket from: ${email}`,
                chatlog: messages,
                ...(comments && { message: comments }),
                channel: 'chatbot',
                token: payload.body.token,
              })
            }
          })
      }}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Flex
        data-testid="describe-issue-view"
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          px: containerPaddingX,
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            zIndex: 'zIndex-1',
            position: 'absolute',
            pointerEvents: 'none',
          }}
        >
          <ChatBackground />
        </Flex>
        <Box sx={{ width: 'inherit' }}>
          <Button
            sx={{
              width: '100%',
              backgroundColor: 'darkJungelGreen',
              height: '48px',
              border: ({ colors }) => `1px solid ${colors?.borderFaint}`,
              borderRadius: '10px',
              pointerEvents: 'none',
              mt: ['24px', '16px'],
            }}
            data-testid="chatlog-attached-button"
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <AttachedIcon />
              <Text sx={{ fontSize: '14px', color: 'caption', fontWeight: 'bold' }}>
                Chatlog Attached
              </Text>
            </Flex>
          </Button>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text
              sx={{
                color: 'title',
                mt: ['32px', '24px'],
                mb: 0,
                fontSize: '28px',
                fontWeight: 600,
              }}
            >
              Talk to a human
            </Text>
            <Text
              sx={{
                color: shouldDisplayErrorMessage ? 'orangeRed' : 'body',
                mt: '8px',
                mb: 0,
                maxWidth: '296px',
                fontSize: '15px',
                fontWeight: 'normal',
                textAlign: 'center',
              }}
            >
              {shouldDisplayErrorMessage
                ? 'Something went wrong, please try sending it again.'
                : "Fill this form out and we'll get back to you as soon as possible by email"}
            </Text>
          </Flex>
          <Box sx={{ mt: ['24px', '16px'], width: '100%' }}>
            <SupportTicketInput
              label="Email"
              inputTestId="ticket_flow_email"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              name="email"
              type="email"
              required={true}
              autoComplete="email"
            />
            <SupportTicketInput
              label="Comments"
              optional={true}
              inputTestId="ticket_flow_comments"
              onChange={({ target }) => setComments(target.value)}
              value={comments}
              name="comments"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 'zIndex3',
              backgroundColor: 'smokyBlack',
              height: '73px',
              mx: `-${containerPaddingX}`,
              borderTop: '1px solid',
              borderColor: 'blueYonder50',
            }}
          >
            <Flex
              sx={{
                px: `${containerPaddingX}`,
                width: '100%',
              }}
            >
              <Button
                variant="text"
                styles={{
                  background: 'transparent',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: 'body',
                  width: '100%',
                  fontSize: '18px',
                }}
                onClick={() => dispatch(pageActions.switchPage(Page.CHAT_PAGE))}
                type="button"
              >
                Back
              </Button>
              <Button
                variant="gradientButton"
                color="black"
                styles={{
                  fontWeight: 'bold',
                  width: '100%',
                  color: 'white',
                  height: '39px',
                  zIndex: 'zIndex2',
                  '& svg': {
                    mt: '-4px',
                  },
                }}
                type="submit"
                data-testid="ticket_flow_submit"
                isLoading={isRequestLoading}
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </form>
  )
}
