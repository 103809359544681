import { ReactElement } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { Page, pageActions } from 'redux/slices/page'
import { Button, Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'

export interface BubbleProps {
  text?: string
  userName?: string
  time?: string
  bubbleSx?: ThemeUIStyleObject
  containerSx?: ThemeUIStyleObject
  needsHuman?: boolean
  hasErrored?: boolean
  showAsMarkdown?: boolean
}

export default function ChatBubble(props: BubbleProps): ReactElement {
  const {
    text,
    userName,
    time,
    hasErrored,
    bubbleSx,
    containerSx,
    needsHuman,
    showAsMarkdown,
    ...rest
  } = props

  const dispatch = useAppDispatch()
  return (
    <Flex
      {...rest}
      sx={{
        width: '100%',
        height: 'min-content',
        flexDirection: 'column',
        px: '16px',
        overflowWrap: 'anywhere',
        overflow: 'hidden',
        ...containerSx,
      }}
    >
      <Text
        sx={{
          fontSize: '13px',
          color: 'caption70',
          fontWeight: 'bold',
          marginBottom: '8px',
        }}
      >
        {userName}
      </Text>
      <Flex
        sx={{
          maxWidth: '90%',
          px: '20px',
          py: '16px',
          borderRadius: '24px',
          flexDirection: 'column',
          gap: '8px',
          ...bubbleSx,
        }}
      >
        <Flex
          data-testid="chat-bubble-text"
          sx={{
            fontSize: '13px',
            color: 'lightCyan',
          }}
        >
          {showAsMarkdown ? (
            <ReactMarkdown
              //Renders the HTML previously generated by linkifyStr.
              rehypePlugins={[remarkGfm]}
              sx={{
                p: { margin: 0, py: '5px' },
                a: { color: 'white50', textDecoration: 'underline' },
                width: '100%',
                img: {
                  width: '100%',
                  height: '100%',
                },
                ol: {
                  p: '0px 16px',
                  li: {
                    my: '8px',
                    pl: '0px',
                    ul: {
                      pl: '16px',
                    },
                  },
                },
                ul: {
                  p: '0px 16px',
                  li: {
                    my: '8px',
                    pl: '0px',
                    ul: {
                      pl: '16px',
                    },
                  },
                },
                pre: {
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-all',
                },
                code: {
                  display: 'inline-block',
                  maxWidth: '100%',
                  fontSize: '10px',
                  backgroundColor: 'smokyBlack70',
                  padding: '5px',
                  borderRadius: '5px',
                },
              }}
            >
              {text ?? ''}
            </ReactMarkdown>
          ) : (
            text
          )}
        </Flex>
        {needsHuman && (
          <Button
            onClick={() => {
              dispatch(pageActions.switchPage(Page.SUBMIT_TICKET))
            }}
            variant="gradientButton"
            data-testid="need-human-button"
            sx={{
              height: '47px',
              fontWeight: 600,
            }}
          >
            Talk to a human
          </Button>
        )}
      </Flex>
      {!hasErrored && (
        <Text sx={{ fontSize: '12px', color: 'lightCyan50', marginTop: '8px' }}>{time}</Text>
      )}
    </Flex>
  )
}
