import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Message } from 'redux/slices/message'

export interface MessageResponse {
  conversationId: string
  intents: Intent[]
  output: string[]
  timestamp: number
}

interface Intent {
  confidence: number
  intent: string
}

export type PreAuthDataResponse = {
  body: {
    token: string
  }
  success?: boolean
  error?: {
    message: string
  }
}

interface BarrySupportTicketRequest {
  email: string
  subject: string
  chatlog: Message[]
  message?: string
  channel: string
  token: string
}

export interface HelloBarryMessageRequest {
  on_page: string
}

export interface HelloBarryMessageResponse {
  body: {
    cta: string
  }
  success: boolean
  message: string
}

const wsApiUrl = process.env.REACT_APP_CD_API_URL

export const cdApi = createApi({
  reducerPath: 'wsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: wsApiUrl,
  }),
  endpoints: builder => ({
    sendSupportTicket: builder.mutation<undefined, BarrySupportTicketRequest>({
      query: body => ({
        url: '/support',
        method: 'POST',
        body,
      }),
    }),
    preAuthToken: builder.mutation<PreAuthDataResponse, { userEmail: string }>({
      query: ({ userEmail }) => ({
        url: '/preauth/support',
        method: 'POST',
        body: { email: userEmail },
      }),
    }),
    getHelloBarryMessage: builder.query<HelloBarryMessageResponse, HelloBarryMessageRequest>({
      query: ({ on_page }) => ({
        url: '/support/hello-barry',
        method: 'GET',
        params: { on_page },
      }),
    }),
  }),
})
