import { alpha } from '@theme-ui/color'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { barryApi } from 'redux/api/barryApi'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { endChat, sendMessage } from 'redux/slices/message'
import { offenceActions } from 'redux/slices/offence'
import { Page, pageActions } from 'redux/slices/page'
import { Box, Button, Flex, Input, Label } from 'theme-ui'
import { maxMessageLength } from 'utils/sizes'
import { ReactComponent as ChatSendIconWhite } from '../../assets/chat-send-icon-white.svg'
import { ReactComponent as ChatSendIcon } from '../../assets/chat-send-icon.svg'
import InboundChatBubble from './InboundChatBubble'
import OutboundChatBubble from './OutboundChatBubble'
import { ReactComponent as ChatBackground } from 'assets/chat-background.svg'
import TypingDotsAnimated from 'components/UI/TypingDotsAnimated'
import useBreakpointIndex from 'theme/useBreakpointIndex'

export default function ChatWindow(): ReactElement {
  const [message, setMessage] = useState('')
  const isMessageEmpty = !message
  const { messages, isBarryTyping } = useAppSelector(x => x.message)
  const lastOffenceTimestamp = useAppSelector(x => x.offence.lastOffenceTimestamp)
  const forceEndConversation = useAppSelector(x => x.offence.forceEndConversation)
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const [, sendMessageResponse] = barryApi.useSendMessageMutation({
    fixedCacheKey: 'sendMessage',
  })
  const isMobile = useBreakpointIndex() === 0

  const dispatch = useAppDispatch()

  function capitalize(text: string) {
    return text[0].toUpperCase() + text.slice(1)
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    scrollAreaRef.current?.scrollTo({
      top: scrollAreaRef.current?.scrollHeight ?? 0,
      behavior: 'smooth',
    })
  }, [messages, isBarryTyping, isMobile])

  useEffect(() => {
    if (messages[messages.length - 1]?.isOffence) {
      if (lastOffenceTimestamp) {
        dispatch(offenceActions.setBan(true))
      }
      dispatch(offenceActions.updateTimestamp(messages[messages.length - 1].timestamp))
      setTimeout(() => {
        dispatch(pageActions.switchPage(Page.SPLASH_PAGE))
        dispatch(endChat())
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: [0, '24px 24px 0 0'],
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <ChatBackground />
      </Flex>
      <Flex
        data-testid="chat-bubble-display"
        ref={scrollAreaRef}
        sx={{
          width: '100%',
          height: 0,
          flex: '1 1 auto',
          flexDirection: 'column',
          paddingTop: ['16px', '16px'],
          gap: '16px',
          borderRadius: [0, '24px 24px 0 0'],
          overflowY: 'auto',
          // hides scroll bar for IE, Edge, and Firefox
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          // hides scoll bar for Chrome, Safari, and Opera
          '::-webkit-scrollbar': {
            display: 'none',
          },
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {messages.map(message => {
            if (!message.fromBarry) {
              return (
                <OutboundChatBubble
                  text={message.content}
                  userName={capitalize(message.sender)}
                  time={message.time}
                  key={message.timestamp}
                  hasErrored={message.hasErrored ?? false}
                  timestamp={message.timestamp}
                  errorMessage={message.errorMessage}
                />
              )
            } else {
              return (
                <InboundChatBubble
                  text={message.content}
                  userName={capitalize(message.sender)}
                  time={message.time}
                  key={message.timestamp}
                  needsHuman={message.needsHuman}
                />
              )
            }
          })}
        </Box>
        <Flex sx={{ mb: '8px' }}>
          <Label
            sx={{
              pl: '16px',
              fontSize: '12px',
              visibility: isBarryTyping ? 'visible' : 'hidden',
              fontWeight: 'bold',
            }}
            color="white50"
            data-testid="barry-is-typing"
          >
            Barry is &quot;typing&quot;
            <TypingDotsAnimated />
          </Label>
          <Label
            sx={{
              fontSize: '12px',
              justifyContent: 'flex-end',
              pr: '16px',
              fontWeight: 'bold',
              visibility: message.length ? 'visible' : 'hidden',
            }}
            color="white50"
            data-testid="message-char-count"
          >
            {message.length}/{maxMessageLength}
          </Label>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          as="form"
          onSubmit={e => {
            e.preventDefault()
            if (message && !sendMessageResponse.isLoading) {
              setMessage('')
              dispatch(sendMessage(message))
            }
          }}
          sx={{
            width: '100%',
            display: forceEndConversation ? 'none' : 'flex',
            height: '56px',
            borderTop: ({ colors }) => `2px solid ${colors?.borderFaint}`,
            backgroundColor: 'blackKnight',
            marginBottom: '-36px',
            zIndex: 'zIndex2',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          <Input
            data-testid="chat-input-field"
            placeholder={'Type message here…'}
            maxLength={maxMessageLength}
            value={message}
            onChange={({ target }) => setMessage(target.value)}
            sx={{
              fontSize: '15px',
              color: 'title',
              marginRight: '16px',
              border: 'none',
              outline: 'none',
              padding: 0,
              '::placeholder': {
                color: 'caption40',
              },
            }}
          />
          <Button
            data-testid="chat-submit-button"
            type="submit"
            sx={{
              minWidth: '40px',
              minHeight: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: isMessageEmpty ? 'darkJungelGreen' : 'none',
              backgroundImage: !isMessageEmpty
                ? 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)'
                : 'none',
              boxShadow: !isMessageEmpty
                ? '0px 1px 0.2px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 10px 0px rgba(97, 63, 219, 0.50)'
                : 'none',
              padding: 0,
              margin: 0,
              WebkitTapHighlightColor: 'transparent',
              ':disabled': {
                backgroundColor: alpha('gray', 0.1),
              },
              ':hover': {
                backgroundImage: !isMessageEmpty
                  ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%);'
                  : 'none',
              },
              display: 'flex',
            }}
            disabled={sendMessageResponse.isLoading}
          >
            {message === '' || sendMessageResponse.isLoading ? (
              <ChatSendIcon />
            ) : (
              <ChatSendIconWhite />
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
