import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { messageActions } from './message'

export interface OffenceState {
  lastOffenceTimestamp?: number | undefined
  isBanned?: boolean
  forceEndConversation?: boolean
}

const initialState: OffenceState = {
  lastOffenceTimestamp: undefined,
  isBanned: false,
  forceEndConversation: false,
}

const offenceSlice = createSlice({
  name: 'offence',
  initialState: initialState,
  reducers: {
    updateTimestamp: (state: OffenceState, action: PayloadAction<number | undefined>) => {
      state.lastOffenceTimestamp = action.payload
    },
    setBan: (state: OffenceState, action: PayloadAction<boolean>) => {
      state.isBanned = action.payload
    },
    setForceEndConversation: (state: OffenceState, action: PayloadAction<boolean>) => {
      state.forceEndConversation = action.payload
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(messageActions.addMessageFromBarry, (state, action) => {
      const endConversation = !!action.payload.output[0].match(/{{action-end-conversation}}/g)

      if (endConversation) {
        state.forceEndConversation = true
      }
    })
    builder.addCase(messageActions.extendChat, state => {
      state.forceEndConversation = false
    })
  },
})

export const { actions: offenceActions, reducer: offenceReducer } = offenceSlice
export default offenceSlice
