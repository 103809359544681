import { alpha } from '@theme-ui/color'
import { ReactElement } from 'react'
import { barryApi } from 'redux/api/barryApi'
import { useAppDispatch } from 'redux/hooks'
import { retrySendMessage } from 'redux/slices/message'
import { Button, Flex } from 'theme-ui'
import ChatBubble from './ChatBubble'

export default function OutboundChatBubble({
  text,
  userName,
  time,
  hasErrored,
  timestamp,
  errorMessage,
}: {
  text: string
  userName: string
  time?: string
  hasErrored: boolean
  timestamp: number
  errorMessage?: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const [, sendMessageResponse] = barryApi.useSendMessageMutation({
    fixedCacheKey: 'sendMessage',
  })

  return (
    <>
      <ChatBubble
        data-testid="outbound-bubble"
        text={text}
        userName={userName}
        time={time}
        hasErrored={hasErrored}
        bubbleSx={{
          borderRadius: '12px',
          backgroundColor: 'midnightGreen',
          boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset;',
          zIndex: 'zIndex2',
          '::before': {
            backgroundColor: 'smokyBlack',
            zIndex: 'zIndex1',
          },
        }}
        containerSx={{ alignItems: 'flex-end' }}
        showAsMarkdown={false}
      />
      {hasErrored && (
        <Flex
          sx={{
            justifyContent: 'end',
            mr: '16px',
            my: '8px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          <Button
            variant="transparent"
            color="orangeRed"
            onClick={() => dispatch(retrySendMessage({ messageToSend: text, timestamp }))}
            data-testid="message-retry"
            sx={{
              ':disabled': {
                backgroundColor: alpha('red', 0.1),
              },
              fontWeight: 'bold',
            }}
            disabled={sendMessageResponse.isLoading}
          >
            {errorMessage}
          </Button>
        </Flex>
      )}
    </>
  )
}
