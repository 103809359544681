import { alpha } from '@theme-ui/color'

/* for new colors please use https://colornamer.robertcooper.me/  
(in the dropdown choose option "Wikipedia Color Names")*/

export const baseColors = {
  black: '#000000',
  screaminGreen: '#55FF8A',
  white: '#FFFFFF',
  body: '#AAB8D9',
  bodyBG: '#041D3E',
  smokyBlack: '#050C1D',
  caption: '#E8EFFF',
  orangeRed: '#FE6A2B',
  blackKnight: '#030814',
  lightCyan: '#E3F9FF',
  mayaBlue: '#63D6FF',
  lightSkyBlue: '#76DBFF',
  red: '#E33F3F',
  gray: '#EFEFEF',
  borderFaint: '#5C6D9633',
  yankeesBlue: '#1C2841',
  darkJungelGreen: '#161F35', // also #102134
  title: '#E8EFFF',
  blueYonder: '#5C6D96',
  spaceCadet: '#1C2841',
  midnightGreen: '#004155',
  aliceBlue: '#F0F8FF',
  endChatButton: '#102134',
}

export const colors = {
  ...baseColors,
  caption70: alpha(baseColors.caption, 0.7),
  caption60: alpha(baseColors.caption, 0.6),
  caption40: alpha(baseColors.caption, 0.4),
  lightCyan70: alpha(baseColors.lightCyan, 0.7),
  lightCyan50: alpha(baseColors.lightCyan, 0.5),
  mayaBlue90: alpha(baseColors.mayaBlue, 0.9),
  mayaBlue70: alpha(baseColors.mayaBlue, 0.7),
  mayaBlue60: alpha(baseColors.mayaBlue, 0.6),
  lightSkyBlue15: alpha(baseColors.lightSkyBlue, 0.15),
  lightSkyBlue10: alpha(baseColors.lightSkyBlue, 0.1),
  lightSkyBlue05: alpha(baseColors.lightSkyBlue, 0.05),
  white50: alpha(baseColors.white, 0.5),
  white25: alpha(baseColors.white, 0.25),
  white15: alpha(baseColors.white, 0.15),
  white10: alpha(baseColors.white, 0.1),
  black30: alpha(baseColors.black, 0.3),
  blackKnight05: alpha(baseColors.blackKnight, 0.05),
  title20: alpha(baseColors.title, 0.2),
  blueYonder90: alpha(baseColors.blueYonder, 0.9),
  blueYonder80: alpha(baseColors.blueYonder, 0.8),
  blueYonder50: alpha(baseColors.blueYonder, 0.5),
  blueYonder30: alpha(baseColors.blueYonder, 0.3),
  blueYonder20: alpha(baseColors.blueYonder, 0.2),
  blueYonder05: alpha(baseColors.blueYonder, 0.05),
  aliceBlue70: alpha(baseColors.aliceBlue, 0.7),
  aliceBlue60: alpha(baseColors.aliceBlue, 0.6),
  aliceBlue30: alpha(baseColors.aliceBlue, 0.3),
  aliceBlue15: alpha(baseColors.aliceBlue, 0.15),
  aliceBlue10: alpha(baseColors.aliceBlue, 0.1),
  smokyBlack70: alpha(baseColors.smokyBlack, 0.7),
}
