export function getLocalStorageItem(key: string): string | null {
  if (typeof window === 'undefined') {
    return null
  }

  let value: string | null = null
  try {
    value = window.localStorage.getItem(key)
  } catch (ex) {
    // localStorage not available (for example, a SecurityError is thrown on iOS when
    // cookies are blocked)
  }

  return value
}

export function setLocalStorageItem(key: string, value: string): void {
  if (typeof window === 'undefined') {
    return
  }

  try {
    localStorage.setItem(key, value)
  } catch (ex) {}
}

export function getBarryDisplayBoolean(): boolean {
  const sessionData = getLocalStorageItem('persist:data') ?? ''
  let shouldDisplayBarry = ''

  try {
    const sessionDataJson = JSON.parse(sessionData)
    shouldDisplayBarry = sessionDataJson.shouldShowBarry ?? ''
  } catch (e) {}

  return shouldDisplayBarry === 'true' ? true : false
}

/*export function getChatBubbleTimeStamp(): number | undefined {
  const sessionData = getLocalStorageItem('persist:data') ?? ''

  try {
    const sessionDataJson = JSON.parse(sessionData)
    const chatBubbleTimestamp = sessionDataJson.hasChatBubbleBeenClosed ?? undefined
  } catch (e) {}

  return chatBubbleTimestamp 
}*/

export function getEmail(): string {
  const sessionData = getLocalStorageItem('persist:session') ?? ''
  let email = ''

  try {
    const sessionDataJson = JSON.parse(sessionData)
    email = sessionDataJson.email ?? ''
  } catch (e) {}

  return email.slice(1, -1)
}

export function getSessionToken(): string {
  const sessionData = getLocalStorageItem('persist:data') ?? ''
  let sessionToken = ''

  try {
    const sessionDataJson = JSON.parse(sessionData)
    sessionToken = sessionDataJson.sessionToken ?? ''
  } catch (e) {
    // TODO: Handle JSON parsing error
  }

  return sessionToken.slice(1, -1)
}
