import { keyframes } from '@emotion/react'

export const wobble = keyframes({
  '0%, 33.3%': {
    transform: 'translateX(0%)',
    transformOrigin: '50% 50%',
  },
  '5.55%': {
    transform: 'translateX(-16px) rotate(-10deg)',
  },
  '11.1%': {
    transform: 'translateX(calc(16px / 2)) rotate(10deg)',
  },
  '16.65%': {
    transform: 'translateX(calc(-16px / 2)) rotate(calc(-10deg / 1.8))',
  },
  '22.2%': {
    transform: 'translateX(calc(16px / 3.3)) rotate(calc(10deg / 3))',
  },
  '27.75%': {
    transform: 'translateX(calc(-16px / 5.5)) rotate(calc(-10deg / 5))',
  },
  '33.3%': {
    transform: 'translateX(0%)',
    transformOrigin: '50% 50%',
  },
})
