import { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { ReactComponent as CloseButtonPopUp } from 'assets/closebutton.svg'
import { ReactComponent as ChatBubbleEdge } from 'assets/bubblebox.svg'

export enum BarryHelloMessagAction {
  HIDE = 'show',
  OPEN = 'open',
}

export default function BarryPopUp({
  barryMessage,
  handleBarryHelloMessage,
}: {
  barryMessage?: string
  handleBarryHelloMessage: (action: BarryHelloMessagAction) => void
}): ReactElement {
  return (
    <>
      <Flex
        sx={{
          width: '200px',
          height: 'auto',
          position: 'absolute',
          bottom: '94px',
          right: ['40px', '48px'],
          boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset',
          fontFamily: 'Plus Jakarta Sans',
          backgroundColor: 'yankeesBlue',
          borderRadius: '12px 12px 0px 12px',
          flexDirection: 'column',
          p: '12px',
        }}
      >
        <Flex
          data-testId="popup-close"
          sx={{
            boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset',
            backgroundColor: 'yankeesBlue',
            p: '3px',
            borderTopLeftRadius: '100px',
            borderTopRightRadius: '100px',
            borderBottomLeftRadius: '100px',
            borderBottomRightRadius: '0px',
            top: '-11px',
            right: '0px',
            position: 'absolute',
          }}
          onClick={() => {
            handleBarryHelloMessage(BarryHelloMessagAction.HIDE)
          }}
        >
          <CloseButtonPopUp />
        </Flex>
        <Flex
          data-testId="popup-message"
          onClick={() => {
            handleBarryHelloMessage(BarryHelloMessagAction.OPEN)
          }}
        >
          <Text
            sx={{
              fontSize: '12px',
              color: 'title',
              fontWeight: 400,
            }}
          >
            {barryMessage}
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          position: 'absolute',
          bottom: '84px',
          right: ['40px', '48px'],
        }}
      >
        <ChatBubbleEdge />
      </Flex>
    </>
  )
}
