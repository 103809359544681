import Button from 'components/UI/Button'
import { ReactElement, useState } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { sendMessage } from 'redux/slices/message'
import { Page, pageActions } from 'redux/slices/page'
import { Flex, Text, Textarea } from 'theme-ui'
import { maxMessageLength } from 'utils/sizes'
import { ReactComponent as BarryIconBackground } from 'assets/barry-background.svg'

const MIN_CHAR_LENGTH = 20

export default function DescribeYourIssue(): ReactElement {
  const [message, setMessage] = useState('')
  const dispatch = useAppDispatch()
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Flex
        sx={{
          zIndex: 'zIndex0',
          position: 'absolute',
          pointerEvents: 'none',
          top: '-180px',
        }}
      >
        <BarryIconBackground />
      </Flex>
      <Flex
        sx={{
          justifyContent: 'center',
          mb: '40px',
        }}
      >
        <Text
          sx={{
            fontSize: '21px',
            wordBreak: 'break-word',
            height: '100%',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
          color="title"
        >
          What can we help you with?
        </Text>
      </Flex>
      <Flex sx={{ mx: ['24px', '16px', '16px'], flexDirection: 'column', alignItems: 'flex-end' }}>
        <Textarea
          sx={{
            resize: 'none',
            zIndex: 'zIndex1',
            overflow: 'scroll',
            // hides scroll bar for IE, Edge, and Firefox
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '15px',
          }}
          variant="textarea.primary"
          rows={5}
          placeholder="Describe your issue or ask any question related to Control D"
          onChange={({ target }) => setMessage(target.value)}
          data-testid="pre-chat-message-input"
          maxLength={maxMessageLength}
        />
        <Text
          color="caption40"
          sx={{
            mt: '16px',
            mr: '4px',
            fontSize: '13px',
            height: '18px',
          }}
          data-testid="char-count"
        >
          {message.length <= MIN_CHAR_LENGTH
            ? `20 characters minimum`
            : `${maxMessageLength - message.length} character${
                message.length == maxMessageLength - 1 ? '' : 's'
              } remaining`}
        </Text>
      </Flex>
      <Flex sx={{ justifyContent: 'center', mt: '32px' }}>
        <Button
          sx={{ minWidth: '198px', width: '100%', mx: '24px' }}
          variant="gradientButton"
          onClick={() => {
            dispatch(pageActions.switchPage(Page.CHAT_PAGE))
            dispatch(sendMessage(message))
          }}
          disabled={message.length < 20}
          data-testid="start-chat-button"
        >
          <Text sx={{ fontSize: '17px' }}>Start chatting</Text>
        </Button>
      </Flex>
    </Flex>
  )
}
