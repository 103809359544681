import { ReactElement } from 'react'
import ChatBubble from './ChatBubble'

export default function InboundChatBubble({
  text,
  userName,
  time,
  needsHuman,
}: {
  text: string
  userName: string
  time?: string
  needsHuman?: boolean
}): ReactElement {
  return (
    <ChatBubble
      data-testid="inbound-bubble"
      text={text}
      userName={userName}
      time={time}
      bubbleSx={{
        borderRadius: '12px',
        width: '100%',
        backgroundColor: 'yankeesBlue',
        boxShadow: '0px 1px 0.2px 0px rgba(255, 255, 255, 0.1) inset',
        zIndex: 'zIndex2',
        '::before': {
          backgroundColor: 'smokyBlack',
          zIndex: 'zIndex1',
        },
      }}
      containerSx={{ alignItems: 'flex-start' }}
      needsHuman={needsHuman}
      showAsMarkdown={true}
    />
  )
}
