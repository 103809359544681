import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { messageActions } from 'redux/slices/message'
import { getTime } from 'utils/time'
const BARRY_API = process.env.REACT_APP_BARRY_API

export interface MessageResponse {
  conversationId: string
  intents: Intent[]
  output: string[]
  timestamp: string
}

export interface MessageRequest {
  conversationId: string
  message: string
  sessionAuthHash?: string
}

interface Intent {
  confidence: number
  intent: string
}

export interface EndChatRequest {
  conversationId: string
}

export const barryApi = createApi({
  reducerPath: 'barryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BARRY_API,
  }),
  endpoints: builder => ({
    sendMessage: builder.mutation<MessageResponse, MessageRequest>({
      query: body => ({
        url: '/message',
        method: 'POST',
        body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const timestamp = Math.floor(Date.now() / 1000)
        dispatch(
          messageActions.addMessage({
            content: arg.message,
            fromAgent: false,
            fromBarry: false,
            sender: 'you',
            timestamp,
            time: getTime(),
          }),
        )

        /*TODO: Add proper return type for error*/
        let errorResponse
        //Checks wether the request failed and adds the error tag to the message
        try {
          await queryFulfilled
        } catch (error) {
          errorResponse = error as { error: { status: number } }
          dispatch(
            messageActions.setMessageError({
              timestamp,
              errorMessage:
                errorResponse.error.status === 429
                  ? 'Rate limit exceeded. Wait a moment and tap to retry'
                  : '!Failed - Tap to resend',
            }),
          )
        }
      },
    }),
    endConversation: builder.mutation<null, EndChatRequest>({
      query: body => ({
        url: '/end-conversation',
        method: 'POST',
        body,
      }),
    }),
  }),
})
