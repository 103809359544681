import { forwardRef, ReactElement, ReactNode } from 'react'
import {
  Flex,
  Input as ThemeUIInput,
  InputProps as ThemeUIProps,
  ThemeUIStyleObject,
} from 'theme-ui'

export interface InputProps extends ThemeUIProps {
  rightContent?: ReactNode
  inputTestId?: string
  containerSx?: ThemeUIStyleObject
}

export default forwardRef<HTMLInputElement, InputProps>(function Input(
  props: InputProps,
  ref,
): ReactElement {
  const { rightContent, inputTestId, containerSx, sx, ...rest } = props
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        height: '48px',
        borderRadius: '12px',
        mt: '8px',
        mb: '16px',
        p: 0,
        backgroundColor: 'smokyBlack',
        border: '1px solid',
        borderColor: 'blueYonder50',
        fontSize: '15px',
        fontWeight: 400,
        letterSpacing: '0.6px',
        ...containerSx,
        '&:hover': {
          borderColor: 'blueYonder90',
        },
      }}
    >
      <ThemeUIInput
        ref={ref}
        sx={{
          flex: 1,
          border: 'none',
          height: '48px',
          color: 'white',
          px: '24px',
          fontSize: '15px',
          outline: 'none',
          position: 'relative',
          caretColor: 'mayaBlue',
          '&:focus': {
            border: '1px solid',
            borderColor: 'mayaBlue90',
            color: 'aliceBlue',
            borderRadius: '12px',
            opacity: 1,
          },
          ...sx,
        }}
        data-testid={inputTestId}
        {...rest}
      />
      {rightContent && (
        <Flex
          sx={{
            marginRight: '19px',
            color: 'white50',
            flexShrink: 0,
          }}
        >
          {rightContent}
        </Flex>
      )}
    </Flex>
  )
})
