import customComponents from './customComponents'
import { colors } from './colors'
import { zIndices } from './zIndices'

const desktopTabletMinHeight = '36em'
const tabletMinWidth = '48em'
const desktopMinWidth = '70em'
const barryOverlapUpperBound = '92.5rem'

const breakpoints = [
  `@media screen and (min-width: ${tabletMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
  `@media screen and (min-width: ${desktopMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
  `@media screen and (min-width: ${tabletMinWidth}) and (max-width: ${barryOverlapUpperBound})`,
]

export default { breakpoints, colors, zIndices, ...customComponents }
