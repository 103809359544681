import MainLayout from 'components/MainLayout'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from 'redux/store'
import { ThemeProvider } from 'theme-ui'
import theme from 'theme'
import { PersistGate } from 'redux-persist/integration/react'

/**
 * This is the entry point for the Barry library bundled with webpack
 */

const container = document.createElement('div')
container.setAttribute('id', 'barry')
document.body.appendChild(container)

const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* TODO: fix me */}
        {/* @ts-ignore */}
        <ThemeProvider theme={theme}>
          <MainLayout />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
