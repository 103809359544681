import React, { ReactElement } from 'react'
import { Text } from 'theme-ui'
import Input from 'components/Input'

const SupportTicketInput = ({
  label,
  inputTestId,
  onChange,
  value,
  name,
  type,
  required,
  optional,
  autoComplete,
}: {
  label: string
  inputTestId: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  name: string
  type?: string
  required?: boolean
  autoComplete?: string
  optional?: boolean
}): ReactElement => {
  return (
    <>
      <Text as="label" sx={{ fontSize: '13px', fontWeight: 'bold' }} color="caption70">
        {label}
      </Text>
      {optional && (
        <Text sx={{ ml: '3px', fontSize: '13px', fontWeight: 'bold' }} color="caption70">
          (optional)
        </Text>
      )}
      <Input
        inputTestId={inputTestId}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        required={required}
        autoComplete={autoComplete}
        sx={{
          p: '16px',
        }}
      />
    </>
  )
}

export default SupportTicketInput
