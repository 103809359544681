import { ReactElement, useState } from 'react'
import { barryApi } from 'redux/api/barryApi'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { endChat } from 'redux/slices/message'
import { Page, pageActions } from 'redux/slices/page'
import { Button, Flex, Text } from 'theme-ui'
import ChatWindow from './chat-ui/ChatWindow'

export default function BarryChatScreen({
  handleBarryIconToggle,
}: {
  handleBarryIconToggle: () => void
}): ReactElement {
  const { needsHuman, conversationId } = useAppSelector(x => x.message)
  const dispatch = useAppDispatch()
  const [isEndOptionOpen, setIsEndOptionOpen] = useState(false)
  const [reason, setReason] = useState<'endChat' | 'talkToHuman' | undefined>(undefined)
  const [endConversationRequest] = barryApi.useEndConversationMutation()
  const forceEndConversation = useAppSelector(x => x.offence.forceEndConversation)

  function switchView(page: Page) {
    dispatch(pageActions.switchPage(page))
  }

  const handleClose = () => {
    setIsEndOptionOpen(true)
    setReason('endChat')
    if (forceEndConversation) {
      dispatch(endChat())
      endConversationRequest({ conversationId })
      handleBarryIconToggle
      switchView(Page.SPLASH_PAGE)
    }
  }

  return (
    <Flex
      data-testid="chat-screen"
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: ['0px', '24px'],
      }}
    >
      <ChatWindow />
      <Flex
        sx={{
          width: '100%',
          height: forceEndConversation ? '48xp' : '96px',
        }}
      >
        {!isEndOptionOpen && (
          <Flex
            sx={{
              width: '100%',
              borderRadius: [0, '0 0 22px 22px'],
              alignItems: 'flex-end',
            }}
          >
            <Button
              data-testid="end-chat-button"
              onClick={handleClose}
              sx={{
                width: '100%',
                height: '48px',
                backgroundColor: 'darkJungelGreen',
                padding: ['16px', '14px'],
                zIndex: 'zIndex2',
                display: 'flex',
                borderRadius: 0,
                justifyContent: 'center',
                alignItems: 'flex-end',
                fontSize: '14px',
                fontWeight: 400,
                color: 'caption',
                WebkitTapHighlightColor: 'transparent',
              }}
            >
              End Chat
            </Button>
            {needsHuman && (
              <Button
                data-testid="escalate-to-human-button"
                onClick={() => {
                  setIsEndOptionOpen(true)
                  setReason('talkToHuman')
                }}
                sx={{
                  borderLeft: '1px solid',
                  borderLeftColor: 'blueYonder30',
                  width: '100%',
                  height: '48px',
                  borderRadius: 0,
                  backgroundColor: 'darkJungelGreen',
                  padding: ['16px', '14px'],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  fontSize: '14px',
                  fontWeight: 400,
                  zIndex: 'zIndex2',
                  WebkitTapHighlightColor: 'transparent',
                  color: 'orangeRed',
                }}
              >
                Escalate to human!
              </Button>
            )}
          </Flex>
        )}
        {isEndOptionOpen && (
          <Flex
            sx={{
              width: '100%',
              backgroundColor: 'darkJungelGreen',
              height: '48px',
              zIndex: 'zIndex2',
              display: 'flex',
              justifyContent: 'space-between',
              alignSelf: 'flex-end',
              fontSize: '14px',
              pt: '12px',
              px: '16px',
              color: 'white',
            }}
          >
            <Text
              sx={{
                mt: '4px',
                color: 'body',
                fontSize: '15px',
              }}
            >
              Are you sure?
            </Text>
            <Flex sx={{ gap: '12px' }}>
              <Button
                data-testid="end-chat-yes"
                variant="secondaryRounded"
                sx={{ fontWeight: 'bold', height: '28px', width: '74px', fontSize: '15px' }}
                onClick={() => {
                  setIsEndOptionOpen(false)
                  switchView(reason === 'talkToHuman' ? Page.SUBMIT_TICKET : Page.SPLASH_PAGE)
                  if (reason === 'endChat') {
                    dispatch(endChat())
                    endConversationRequest({ conversationId })
                    handleBarryIconToggle
                  }
                }}
              >
                Yes
              </Button>
              <Button
                data-testid="end-chat-no"
                variant="secondaryRounded"
                sx={{ fontWeight: 'bold', height: '28px', width: '74px', fontSize: '15px' }}
                onClick={() => setIsEndOptionOpen(false)}
              >
                No
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
